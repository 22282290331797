<div class="kt-portlet kt-portlet--height-fluid recent-tenants">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">
                <i class="fas fa-user-plus kt-font-primary"></i>
                <span
                    class="caption-subject kt-font-primary">{{"RecentTenants" | localize}}</span><br />
                <small>
                    <span class="caption-helper" *ngIf="!loading">
                        {{"RecentTenantsHelpText" | localize:recentTenantsData.recentTenantsDayCount : recentTenantsData.maxRecentTenantsShownCount}}
                    </span>
                </small>
            </h3>
        </div>
    </div>
    <div class="kt-portlet__body" >
        <div class="scroller chart kt-scroll" [perfectScrollbar]>
            <div class="primeng-datatable-container recent-tenants-table" *ngIf="!loading"
                [busyIf]="primengTableHelper.isLoading">
                <p-table #RecentTenantsTable [value]="recentTenantsData.recentTenants" 
                    rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false"
                    [lazy]="false" [responsive]="primengTableHelper.isResponsive">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'TenantName' | localize}}</th>
                            <th>{{'CreationTime' | localize}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-record="$implicit">
                        <tr>
                            <td>
                                <span class="ui-column-title">{{'TenantName' | localize}}</span>
                                {{record.name}}
                            </td>
                            <td>
                                <span
                                    class="ui-column-title">{{'CreationTime' | localize}}</span>
                                {{ record.creationTime | momentFormat:'L LT'}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="primeng-no-data" *ngIf="recentTenantsData.recentTenants.length == 0">
                    {{'NoData' | localize}}
                </div>
            </div>
        </div>
        <div class="scroller-footer">
            <div class="btn-arrow-link pull-right">
                <a href="javascript:;" class="see-all-recent-tenants"
                    (click)="gotoAllRecentTenants()">{{"SeeAllRecords" | localize}}</a>
                <i class="icon-arrow-right"></i>
            </div>
        </div>
    </div>
</div>