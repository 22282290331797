import { Injectable } from '@angular/core';
import { RefreshTokenService } from 'abp-ng2-module';
import { TokenAuthServiceProxy, RefreshTokenResult } from '@shared/service-proxies/service-proxies';
import { TokenService } from 'abp-ng2-module';
import { Observable, Subject, of } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class ZeroRefreshTokenService implements RefreshTokenService {
  constructor(
    private _tokenAuthService: TokenAuthServiceProxy,
    private _tokenService: TokenService
  ) { }

  tryAuthWithRefreshToken(): Observable<boolean> {
    let refreshTokenObservable = new Subject<boolean>();

    let token = this._tokenService.getRefreshToken();
    if (!token || token.trim() === '') {
      return of(false);
    }

    this._tokenAuthService.refreshToken(token)
      .subscribe(
        (tokenResult: RefreshTokenResult) => {
          if (tokenResult && tokenResult.accessToken) {            
            abp.auth.setToken(tokenResult.accessToken);
                        
            sessionStorage.setItem(AppConsts.authorization.encrptedAuthTokenName, tokenResult.encryptedAccessToken);
            
            refreshTokenObservable.next(true);
          } else {
            refreshTokenObservable.next(false);
          }
        },
        () => {
          refreshTokenObservable.next(false);
        }
      );
    return refreshTokenObservable;
  }
}
