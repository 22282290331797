import { Injectable } from '@angular/core';
import { AppConfigurationServiceServiceProxy } from '@shared/service-proxies/service-proxies';
import { ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
}
)
export class AppConfigService {
  
    private configurations = new ReplaySubject<any>(1);

    public configurations$ = this.configurations.asObservable();

       constructor(
        private _serviceProxy: AppConfigurationServiceServiceProxy
    ) {
        this.updateConfig();
    }
    
    updateConfig() {
        this._serviceProxy.configuration().subscribe(config => {
            this.configurations.next(config);           
        })
    }
    

    
}

