import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { DashboardService } from "@app/services/dashboard.service";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class EmissionsAIRouteGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _sessionService: AppSessionService,
        private dashboardService: DashboardService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean>  {
        return this.dashboardService.selectedAsset$.pipe(
          map(e => {
            if (e.hasEmissionAI) return true;
            else {
                this._router.navigate([this.selectBestRoute()]);
                return false;
            }
          })
        );
    }   
      

    

    selectBestRoute(): string {
        if (this._sessionService.tenant.tenantHomePage)
            return this._sessionService.tenant.tenantHomePage;
        
        return "/app/main/communications/XInsights";
    }
}
