import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Duration } from 'moment';
import { Router } from '@angular/router';
import { IAsset } from '@app/main/Models/IAsset';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { Dictionary } from '@amcharts/amcharts4/core';

const localStorageSessionIdKey: string = "Opex.Analytics.SessionId";
const localStoragePageViewIdKey: string = "Opex.Analytics.PageViewId";
const localStorageApiTokenKey: string = "Opex.Analytics.Token";

@Injectable()
export class AnalyticsService {

    private http: HttpClient;
    private baseUrl: string;
    private baseApiVersion: string = "v1";
    private applicationId: string;
    private apiKey: string;

    private pageStartRequest: Subscription;

    constructor(@Inject(HttpBackend) handler: HttpBackend, private router: Router, private appSession: AppSessionService) {
        this.http = new HttpClient(handler);

        this.baseUrl = environment.analytics.baseUrl;
        this.applicationId = environment.analytics.applicationId;
        this.apiKey = environment.analytics.apiKey;
    }

    /**
     * Gets the Http Header options.
     * @returns The Http Header options for connecting to the analytics api.
     */
    private getHttpHeaderOptions(token: string) {
        // Create the headers for the request
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'bearer ' + token });
        let options = { headers: headers };

        return options;
    }
    
    /**
     * Gets a token for the user analytics api
     */
    getUserAnalyticsToken(): Observable<TokenModel> {
        // Attempt to get the token details from local storage
        var localStorageItem = localStorage.getItem(localStorageApiTokenKey);
        if (localStorageItem) {
            // We have the token details, check that it has not expired, if it has, we need a new one
            let tokenDetails = JSON.parse(localStorageItem) as TokenModel;
            let currentTime = moment(new Date());
            let tokenExpiry = moment(tokenDetails.expiryDate);
            if (tokenExpiry > currentTime) {
                return of(tokenDetails);
            }
        }

        // Token hasn't been set, or has expired, so get a new token
        var loginModel = new LoginModel(environment.analytics.applicationId, environment.analytics.apiKey);
        let authenticationUrl = this.baseUrl + this.baseApiVersion + "/Authentication/Authenticate";
        // Make the request
        return this.http.post<TokenModel>(authenticationUrl, loginModel)
                        .pipe(
                            tap(data => {
                                localStorage.setItem(localStorageApiTokenKey, JSON.stringify(data));
                            })
                        );
    }

    /**
     * Records the user analytics
     */
    recordUserAnalytics(selectedAsset: IAsset, includePageData: boolean){
        console.trace("Navigated to: " + this.router.url);
        
        let root = this.router.routerState.snapshot.root;
        
        let pageTitle = "";
        let pageData = "{";
        // Add the asset the user is on by default
        if (selectedAsset)
            pageData += "\"asset\": \"" + selectedAsset.assetName + "\"";
        
        try {
            while (root) {
                if (includePageData){
                    // Get the route data
                    for (const paramMapKey of root.paramMap.keys) {
                        console.trace(paramMapKey + ": " + root.paramMap.get(paramMapKey));
                        if (pageData !== "{")
                            pageData += ", ";
                        pageData += "\"" + paramMapKey + "\": \"" + root.paramMap.get(paramMapKey) + "\"";
                    }
                }
                
                if (root.children && root.children.length) {
                    root = root.children[0];
                    // Get the url if it is not the app or main portions of the url and there are not params
                    if (root.url.toString() !== 'app' && root.url.toString() !== 'main' && root.url.toString() !== '' && root.paramMap.keys.length === 0){
                        if (pageTitle !== '')
                            pageTitle = pageTitle + ",";
                            
                        pageTitle = pageTitle + root.url.toString();
                        console.log("Page Route: " + pageTitle);
                    }
                } else {
                    break;
                }
            }
        }
        catch(ex){
            console.log("Error occurred getting page data: " + ex.message);
        }
        
        pageData += "}";
        console.log("Page Data: " + pageData);
        
        if (pageTitle) {
            // Record the start of the page view
            this.analyticsStartPageView(pageTitle, pageData);
        }        
    }

    /**
     * Starts the analytics page view.
     * @param pageTitle The title or route of the page.
     * @param pageData The data passed to the page.
     */
    analyticsStartPageView(pageTitle: string, pageData: string){
        // Get the current username and tenant of the logged in user
        let tenancyName = this.appSession.tenancyName;
        let userName = this.appSession.user.userName;
        // Start the page view
        this.startPageView(pageTitle, pageData, userName, tenancyName);
    }

    /**
     * Records the start of the session.
     * Will store the Session Id in session storage for future use.
     * @param username The username of the logged in user.
     * @param tenant The tenant that the user is logged into.
     */
    startSession(username: string, tenant: string) {
        // Create the model for passing to the request
        let sessionDetails = new StartSessionViewModel(username, tenant);

        let sessionStartUrl = this.baseUrl + this.baseApiVersion + "/Session/Start";
        // Start the session, and record the response in local storage
        this.getUserAnalyticsToken().subscribe(token => {
            this.http.post<string>(sessionStartUrl, sessionDetails, this.getHttpHeaderOptions(token.token))
                 .subscribe((data: string) => {
                    console.log("Session Id: " + data);
                    // Store the session id in local storage
                    sessionStorage.setItem(localStorageSessionIdKey, data);
                });
        });
    }

    /**
     * Records the end of the session and clears the local storage.
     */
    endSession(){
        // Get the session id
        let sessionId = sessionStorage.getItem(localStorageSessionIdKey);
        if (sessionId !== null) {
            let sessionEndUrl = this.baseUrl + this.baseApiVersion + "/Session/End/" + sessionId;
            // End the session
            console.log("Ending session in analytics service.");
            this.getUserAnalyticsToken().subscribe(token => {
                this.http.post<string>(sessionEndUrl, null, this.getHttpHeaderOptions(token.token))
                    .subscribe(() => {
                        console.log("Session has ended.");
                        // Remove the item from session storage
                        sessionStorage.removeItem(localStoragePageViewIdKey);
                        sessionStorage.removeItem(localStorageSessionIdKey);
                    });
            });
        }
    }

    /**
     * Records the start of the page view.
     * Will store the returned Session Id and Page View Id in local storage.
     * @param pageTitle The title of the page the user is starting to view.
     * @param pageData The data passed to the page.
     * @param username The username of the logged in user.
     * @param tenant The tenant that the user is logged into.
     */
    async startPageView(pageTitle: string, pageData: string, username: string, tenant: string) {

        // Only record the pageview if the title is set
        if (!pageTitle)
            return;

        // Get the session id
        let sessionId = sessionStorage.getItem(localStorageSessionIdKey);
        // Get the previous page view id, if it exists
        let previousPageViewId = sessionStorage.getItem(localStoragePageViewIdKey);
        
        // Create the model for passing to the request
        let pageViewDetails = new StartPageViewViewModel(sessionId, pageTitle, pageData, previousPageViewId, username, tenant);
        
        // Start the page view
        let pageViewStartUrl = this.baseUrl + this.baseApiVersion + "/PageView/Start";
        
        // Start the page view, and record the response in local storage
        const token = await this.getUserAnalyticsToken().toPromise();

        if (!this.pageStartRequest) {
            this.pageStartRequest = this.http.post<StartPageViewResponseViewModel>(pageViewStartUrl, pageViewDetails, this.getHttpHeaderOptions(token.token))
                .subscribe((data: StartPageViewResponseViewModel) => {
                    console.log("Session Id: " + data.sessionId);
                    console.log("Page View Id: " + data.pageViewId);
                    // Store the session id and page view id in local storage
                    sessionStorage.setItem(localStorageSessionIdKey, data.sessionId);
                    sessionStorage.setItem(localStoragePageViewIdKey, data.pageViewId);
                    this.pageStartRequest = null;
                });
        }
    }

    /**
     * Records the end of the page view.
     */
    endPageView() {
        // Get the page view id
        let pageViewId = sessionStorage.getItem(localStoragePageViewIdKey);
        if (pageViewId !== null) {
            let pageViewEndUrl = this.baseUrl + this.baseApiVersion + "/PageView/End/" + pageViewId;
            // End the page view
            console.log("Ending page view in analytics service.");
            this.getUserAnalyticsToken().subscribe(token => {
                this.http.post<string>(pageViewEndUrl, null, this.getHttpHeaderOptions(token.token))
                    .subscribe(() => {
                        console.log("Page View has ended.");
                    });
            });
        }
    }

    /**
     * Gets the session overview data
     */
    getSessionOverviews(startDate: Date, endDate: Date, username: string, tenant: string): Observable<SessionOverview[]> {
        // Construct the url
        var sessionOverviewUrl = this.baseUrl + this.baseApiVersion + "/Session/GetSessions?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + "%2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<SessionOverview[]>(sessionOverviewUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets the session total time summary data
     */
    getSessionTotalTimeSummaries(startDate: Date, endDate: Date, username: string, tenant: string): Observable<SessionTotalTimeSummary[]> {
        // Construct the url
        var sessionTotalTimeUrl = this.baseUrl + this.baseApiVersion + "/Session/GetSessionTotalTimeSummary?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + "%2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<SessionTotalTimeSummary[]>(sessionTotalTimeUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets the session counts for each day over period
     */
     GetSessionCountsOverPeriod(startDate: Date, endDate: Date, username: string, tenant: string): Observable<SessionCount[]> {
        // Construct the url
        var sessionCountsOverPeriodUrl = this.baseUrl + this.baseApiVersion + "/Session/GetSessionsOverPeriodByDay?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + "%2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<SessionCount[]>(sessionCountsOverPeriodUrl, this.getHttpHeaderOptions(token.token));
            })
        );
    }

    /**
     * Gets the session total time summary data
     */
     getSessionTimeOfDaySummaries(startDate: Date, endDate: Date, username: string, tenant: string): Observable<SessionTimeOfDaySummary[]> {
        // Construct the url
        var sessionTimeOfDayUrl = this.baseUrl + this.baseApiVersion + "/Session/GetSessionTimeOfDaySummary?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + "%2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<SessionTimeOfDaySummary[]>(sessionTimeOfDayUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets the unique usernames of all those that have been tracked in the analytics
     * can filter list by optional param tenant
     */
    getUniqueUsernames(tenant?: string): Observable<string[]> {
        // Get the url
        var usernameUrl = this.baseUrl + this.baseApiVersion + "/Session/GetUniqueUsernames?tenant=" + (tenant ? tenant : '');
        // Get the unique usernames
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<string[]>(usernameUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets the unique tenant names of all those that have been tracked in the analytics
     */
    getUniqueTenants(): Observable<string[]> {
        // Get the url
        var tenantUrl = this.baseUrl + this.baseApiVersion + "/Session/GetUniqueTenants";
        // Get the unique usernames
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<string[]>(tenantUrl, this.getHttpHeaderOptions(token.token));
            }));
    }
    
    /**
     * Gets the page view overview data
     */
     getPageViewOverviews(startDate: Date, endDate: Date, username: string, tenant: string): Observable<PageViewOverview[]> {
        // Construct the url
        var pageViewOverviewUrl = this.baseUrl + this.baseApiVersion + "/PageView/GetPageViews?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + "%2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<PageViewOverview[]>(pageViewOverviewUrl, this.getHttpHeaderOptions(token.token));
            }));
    }
    
    /**
     * Gets the page view overview data
     */
     getPageViewTotalTimeSummaries(startDate: Date, endDate: Date, username: string, tenant: string): Observable<PageViewTotalTimeSummary[]> {
        // Construct the url
        var pageViewTotalTimeUrl = this.baseUrl + this.baseApiVersion + "/PageView/GetPageViewTotalTimeSummary?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + " %2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<PageViewTotalTimeSummary[]>(pageViewTotalTimeUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets all page view counts across day in range
     */
     getPageViewsAcrossDaysInRange(startDate: Date, endDate: Date, username: string, tenant: string): Observable<PageViewsAcrossDayChartData> {
        // Construct the url
        var pageViewTotalTimeUrl = this.baseUrl + this.baseApiVersion + "/PageView/GetPageViewsAcrossDaysInRange?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + " %2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<PageViewsAcrossDayChartData>(pageViewTotalTimeUrl, this.getHttpHeaderOptions(token.token));
            }));
    }

    /**
     * Gets the page view overview data
     */
     getPageTotalsAcrossDayInRange(startDate: Date, endDate: Date, username: string, tenant: string): Observable<PageTotalsAcrossDay[]> {
        // Construct the url
        var pageViewTotalTimeUrl = this.baseUrl + this.baseApiVersion + "/PageView/GetPageTotalsAcrossDayInRange?startdate=" + startDate.toISOString().split('T')[0] + "&enddate=" + endDate.toISOString().split('T')[0] + " %2023:59:59&username=" + (username === null ? '' : username) + "&tenant=" + (tenant === null ? '' : tenant);
        // Get the session overview data
        return this.getUserAnalyticsToken().pipe(
            switchMap(token => {
                return this.http.get<PageTotalsAcrossDay[]>(pageViewTotalTimeUrl, this.getHttpHeaderOptions(token.token));
        }));
    }
}

export class LoginModel {
    constructor(public id: string, public apiKey: string) { }
}

export class TokenModel {
    constructor(public token: string, public expiryDate: moment.Moment){}
}

export class StartSessionViewModel {
    private referrer: string;

    constructor(private username: string, private tenant: string) { 
        this.referrer = document.referrer;
    }
}

export class StartPageViewViewModel {
    constructor(private sessionId: string | null, 
        private pageTitle: string, 
        private pageData: string, 
        private previousPageViewId: string | null,
        private username: string,
        private tenant: string) {}
}

export interface StartPageViewResponseViewModel {
    sessionId: string;
    pageViewId: string;
}

export class SessionOverview {
    constructor(public sessionId: string, public application: string, public tenant: string, public username: string,
        public startTime: Date, public endTime: Date | null, public sessionLength: Duration) {}
}

export class SessionTotalTimeSummary {
    constructor(public session: string, public totalSessionSeconds: number) {}
}

export class SessionTimeOfDaySummary {
    constructor(public time: number, public started: number, public ended: number) {}
}

export class PageViewOverview {
    constructor(public sessionId: string, public application: string, public tenant: string, public username: string, public page: string,
        public startTime: Date, public endTime: Date | null, public pageViewLength: Duration) {}
}

export class PageViewTotalTimeSummary {
    constructor(public page: string, public numberOfViews: number, public totalPageViewSeconds: number){}
}

export class SessionCount {
    constructor(public count: number, public date: Date){}
}

export class PageViewsAcrossDaysInRange {
    constructor(public date: Date, public pageCounts: Dictionary<string, number>){}
}

export class PageTotalsAcrossDay extends PageViewTotalTimeSummary {
    constructor(public page: string, public numberOfViews: number, public totalPageViewSeconds: number, public date: Date){
        super(page, numberOfViews, totalPageViewSeconds);
    }
}

export class PageViewsAcrossDayChartData {
    constructor(public chartData: PageViewsAcrossDaysInRange[], public uniquePages: string[]){}
}