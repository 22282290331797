import { Injectable } from '@angular/core';
import { AssetsServiceServiceProxy, FlareStackDto, FlaringServiceServiceProxy, IAssetCategorySetDto, IFlareStackDto, VerificationStatusResource } from '@shared/service-proxies/service-proxies';
import { ReplaySubject } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { IAsset } from '../main/Models/IAsset';
/*
* Service for component interaction in XPAS 
*/

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
 
    // Subject for the selected asset
    private selectedAsset = new ReplaySubject<IAsset>(1);
    // Behaviour subject for all assets array
    private allAssets = new ReplaySubject<any[]>(1);    
    // Observable streams.
    public selectedAsset$ = this.selectedAsset.asObservable();
    public allAssets$ = this.allAssets.asObservable();

    // Subject for the flare
    private selectedFlare = new ReplaySubject<IFlareStackDto>(1);
    public selectedFlare$ = this.selectedFlare.asObservable();
    private allFlares = new ReplaySubject<any[]>(1);    
    public allFlares$ = this.allFlares.asObservable();

    // Current flaring category set
    private currentFlareCategorySet = new ReplaySubject<IAssetCategorySetDto>(1);
    public currentFlareCategorySet$ = this.currentFlareCategorySet.asObservable();

    insightFilters:IInsightFilters = {
        sortOrder: null,
        sortField: null,
        freeTextSearchFilter: '',
        systemFilter: 'All',
        subSystemFilter: 'All',
        unitFilter: 'All',
        createdDateFilter: null,
        priorityFilter: 'All',
        statusFilter: 'All'      
    }

    investigationFilters: IInvestigationFilters = {
        sortOrder: null,
        sortField: null,
        freeTextSearchFilter: '',
        createdDateFilter: null,
        systemFilter: 'All',
        subSystemFilter: 'All',
        unitFilter: 'All',
        actionFilter: 'All',
        priorityFilter: 'All',
        statusFilter: 'open'
    }

    oilReportFilters: IOilReportFilters = {
        status: 10,
        verificationStatus: VerificationStatusResource.NotVerified,
        freeTextSearchFilter: '',
    }

      /**
     *
     */
    constructor(private _abpCommsAssetsServiceProxy: AssetsServiceServiceProxy, private flaringService: FlaringServiceServiceProxy) {
        this.updateAssets();
        this.initialiseSellectedAsset();
    }

    // Update the assets with the current backend data.
    updateAssets() {
        this._abpCommsAssetsServiceProxy.getAllForUser().pipe(
            map(assets => assets.map(asset => {
                return {
                    hasEmissionAI: asset.abpCommsAssets.hasEmissionAI,
                    assetId: asset.abpCommsAssets.id,
                    assetName: asset.abpCommsAssets.asset_name, //.toLowerCase(),
                    hasFlaring: asset.abpCommsAssets.hasFlaring,
                    flaringCategorySets: asset.abpCommsAssets.flaringCategorySets,
                    timezone: asset.abpCommsAssets.timezone,
                    dataTimezone: asset.abpCommsAssets.dataTimezone,
                    timezoneOffset: asset.abpCommsAssets.timezoneOffset
                };
            }))
        ).subscribe(assets => {
            this.announceAllAssets(assets);            
        });
    }

    // Initialise the selected asset with the first item of the array returned by allAssets$.
    initialiseSellectedAsset() {
        this.allAssets$.pipe(take(1)).subscribe(assets => {
            this.announceAsset(assets[0]);
        })        
    }

    // Service message commands
    announceAsset(asset: IAsset) {
        console.log('Announcing a new asset selected!', asset);
        this.selectedAsset.next(asset);
        // If the asset has flaring, get the flares
      //  if (asset.hasFlaring){
            this.getAssetFlares(asset);
            // Set the current falring category set
            if (asset.flaringCategorySets.length === 1){
                this.announceFlaringCategorySet(asset.flaringCategorySets[0]);
            }
            else {
                var date = new Date();
                for (var i = 0; i < asset.flaringCategorySets.length; i++) {
                    var categorySet = asset.flaringCategorySets[i];
                    if (categorySet.startDate.toDate() < date && categorySet.endDate.toDate() > date) {
                        this.announceFlaringCategorySet(categorySet);
                    }
                }
            }
      //  }
        // else{
        //     this.announceAllFlares(new FlareStackDto[0]);
        //     this.announceFlare(null);
        //     this.announceFlaringCategorySet(null);
        // }
    }

    // Service method to announce the array of all assets
    announceAllAssets(allAssets: any[]) {
        console.log('Announcing all assets update!', allAssets);
        this.allAssets.next(allAssets);
    } 

    public setInsightFilters(newSet: IInsightFilters) {
        this.insightFilters = newSet;
    }

    public setOilReportFilters(newSet: IOilReportFilters) {
        this.oilReportFilters = newSet;
    }

    /**
     * Gets the flares for the asset
     */
    getAssetFlares(asset: IAsset) {
        console.log('Getting flare stacks for asset!', asset);
        this.flaringService.getAllFlareStacks(asset.assetId).subscribe(result => {
            if (!result) result = new Array();
            if (result.length === 0) result.push(new FlareStackDto({id: "0", name: "All"}));
            this.announceAllFlares(result);
            // Set the initial flare to be the first
            this.announceFlare(result[0]);
        });
    }

    // Service message commands
    announceFlare(flare: IFlareStackDto) {
        console.log('Announcing a new flare selected!', flare);
        this.selectedFlare.next(flare);
    }

    // Service method to announce the array of all assets
    announceAllFlares(allFlares: IFlareStackDto[]) {
        console.log('Announcing all flares update!', allFlares);
        this.allFlares.next(allFlares);
    } 

    announceFlaringCategorySet(categorySet: IAssetCategorySetDto) {
        console.log('Announcing a new flaring category set selected!', categorySet);
        this.currentFlareCategorySet.next(categorySet);
    }
}

export interface IInsightFilters {
    sortOrder: any;
    sortField: any;
    freeTextSearchFilter: string;
    systemFilter: string;
    subSystemFilter: string;
    unitFilter: string;
    createdDateFilter: string;
    priorityFilter: string;
    statusFilter: string;
}

export interface IInvestigationFilters extends IInsightFilters {
    actionFilter: string;
}

export interface IOilReportFilters {
    status: number;
    verificationStatus: number;
    freeTextSearchFilter: string;
}