import { PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { DashboardService } from '@app/services/dashboard.service';
import { IAsset } from '@app/main/Models/IAsset';

@Injectable()
export class AppNavigationService {

    public selectedAsset: IAsset = null;
    
    constructor(private _permissionCheckerService: PermissionCheckerService, private _appSessionService: AppSessionService,
                private dashboardService: DashboardService) {
        /* Subscribe the selectedAsset to the selectedAsset Subject from the dashboard service.
        *   Every time the dashboard emmits a new value, it is assigned to the selectedAsset in this component.
        */
        this.dashboardService.selectedAsset$.subscribe(asset => {
            this.selectedAsset = asset;
        });
    }

    getMenu(): AppMenu {

        var xpasUrls: AppMenuItem[] = [];
        if(this._appSessionService.tenant && this._appSessionService.tenant.generatedXpasUrls)
            this._appSessionService.tenant.generatedXpasUrls.forEach(generatedUrl => {
                xpasUrls.push(new AppMenuItem(generatedUrl.assetName,'','', generatedUrl.url, [], true))
            });

        return new AppMenu('MainMenu', 'MainMenu', [

            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', '', '/app/admin/hostDashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', '', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', '', '/app/admin/editions'),
            new AppMenuItem('Support', 'Pages.OptixSupports', '', '/app/main/support'),
            new AppMenuItem('Greenfield Notification', 'Pages.User.GreenfieldNotification', '', '/app/main/greenfield-notification'),
            new AppMenuItem('Oil Sampling', 'Pages.Oil.Vibrations', '', '', [
                new AppMenuItem('Reports', '', '', "/app/main/oilsampling/reports"),
                new AppMenuItem('Schedule', '', '', "", [
                    new AppMenuItem('Schedules', '', '', "/app/main/oilsampling/schedules"),
                    new AppMenuItem('Compliance','','',"/app/main/oilsampling/schedules/compliance")
                ]),
            ]),
            new AppMenuItem('User Analytics', 'Pages.User.Analytics', '', '/app/main/user-analytics'),
            new AppMenuItem('emissions.AI', 'Pages.Emissions', '', '', [
                new AppMenuItem('Energy Efficiency ', 'Pages.Emissions.Efficiency', '', '', [                    
                    new AppMenuItem("Home", 'Pages.Emissions.Efficiency.Home', '', "/app/main/emissions/efficiency/home"),
                    new AppMenuItem("e.AI Operational Report", 'Pages.Emissions.Efficiency.AI.Operational.Report', '', "/app/main/emissions/efficiency/ai-operational-report"),
                    new AppMenuItem("Analysis", 'Pages.Emissions.Efficiency.Analysis', '', "/app/main/emissions/efficiency/analysis"),
                    new AppMenuItem("Turbine Analysis", 'Pages.Emissions.Efficiency.Turbine.Analysis', '', "/app/main/emissions/efficiency/turbine-analysis"),
                    new AppMenuItem("Power Generation", 'Pages.Emissions.Efficiency.Power.Generation', '', "/app/main/emissions/efficiency/power-generation"),
                    new AppMenuItem("Furnace Analysis", 'Pages.Emissions.Efficiency.Furnace.Analysis', '', "/app/main/emissions/efficiency/furnace-analysis"),
                    new AppMenuItem("Steam Analysis", 'Pages.Emissions.Efficiency.Steam', '', "/app/main/emissions/efficiency/steam-analysis"),
                    new AppMenuItem("Fired Heaters", 'Pages.Emissions.Efficiency.FireHeaters', '', "/app/main/emissions/efficiency/fire-heaters"),
                    new AppMenuItem("Boilers", 'Pages.Emissions.Efficiency.Boilers', '', "/app/main/emissions/efficiency/boilers"),
                    new AppMenuItem("Heaters (WHRU)", 'Pages.Emissions.Efficiency.Whru', '', "/app/main/emissions/efficiency/waste-heat-recovery"),
                    new AppMenuItem("History", 'Pages.Emissions.Efficiency.History', '', "/app/main/emissions/efficiency/history"),
                    new AppMenuItem("Summary", 'Pages.Emissions.Efficiency.Summary', '', "/app/main/emissions/efficiency/summary"),
                    new AppMenuItem("Statistical Analysis", 'Pages.Emissions.Efficiency.Opportunity', '', "/app/main/emissions/efficiency/opportunity"),
                    new AppMenuItem("System Status", 'Pages.Emissions.Efficiency.SystemStatus', '', "/app/main/emissions/efficiency/system-status"),
                ]),
                new AppMenuItem('Flaring and Venting', 'Pages.Emissions.Flaring', '', '', [
                    new AppMenuItem('Summary', 'Pages.Emissions.Flaring.Summary', '', '/app/main/emissions/flaring/summary'),
                    new AppMenuItem('Analysis', 'Pages.Emissions.Flaring.Analysis', '', '/app/main/emissions/flaring/analysis'),
                    new AppMenuItem('Reconciliation', 'Pages.Emissions.Flaring.Reconciliation', '', '/app/main/emissions/flaring/reconciliation'),
                ]),
                new AppMenuItem('Methane', 'Pages.Emissions.Methane', '', '/app/main/emissions/methane'),
           //     new AppMenuItem('Methane', 'Pages.Emissions.Methane', '', "/app/main/communications/dashboard"),
                new AppMenuItem('Oil In Water', 'Pages.Emissions.OilInWater', '', "/app/main/communications/dashboard"),
            ]),
            new AppMenuItem('Communications', 'Pages.Communications', '', '', [
                new AppMenuItem("Dashboard", 'Pages.Communications.Dashboard', '', "/app/main/communications/dashboard"),
                new AppMenuItem("X-Observation", 'Pages.AbpCommsXObservation', '', "/app/main/communications/xobservation"),
                new AppMenuItem("X-Investigation", 'Pages.AbpCommsXInvestigation', '', "/app/main/communications/XInvestigations"),
                new AppMenuItem("X-Insights", 'Pages.AbpCommsXInsight', '', "/app/main/communications/XInsights")
            ]),            
            new AppMenuItem('X-PAS', 'Pages.Xpas', '', '', xpasUrls),
            new AppMenuItem('Reports', 'Pages.Reports', '', '/app/main/reports'),
            new AppMenuItem('Management', 'Pages.AbpCommsSystemTeams', '', '', [
                new AppMenuItem('Assets', '', '', '', [
                    new AppMenuItem('View', 'Pages.AbpCommsAssets', 'flaticon-folder-1', '/app/main/management/assets/view'),
                    new AppMenuItem('Asset Association', 'Pages.AbpCommsAssets', 'flaticon-settings', '/app/main/management/assets/association'),
                    new AppMenuItem('Asset Settings', 'Pages.AbpCommsAssets', 'flaticon-settings', '/app/main/management/assets/settings'),
                ]),
                new AppMenuItem('Inventory', '', '', '', [
                    new AppMenuItem('View', 'Pages.AbpCommsInventory', 'flaticon-folder-1', '/app/main/management/inventory/view'),
                    new AppMenuItem('Manage Inventory', 'Pages.AbpCommsInventory', 'flaticon-settings', '/app/main/management/inventory/manage'),
                ]),
                new AppMenuItem('System Teams', '', '', '', [
                    new AppMenuItem('View', 'Pages.AbpCommsSystemTeams', 'flaticon-folder-1', '/app/main/management/systemteams/view'),
                    new AppMenuItem('Manage System Teams', 'Pages.AbpCommsSystemTeams', 'flaticon-settings', '/app/main/management/systemteams/manage'),
                ]),
            ]),
            new AppMenuItem('Administration', '', '', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'flaticon-map', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'flaticon-suitcase', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'flaticon-users', '/app/admin/users'),
                //new AppMenuItem('Languages', 'Pages.Administration.Languages', 'flaticon-tabs', '/app/admin/languages'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.Host.Settings', 'flaticon-folder-1', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                new AppMenuItem('Subscription', 'Pages.Administration.Host.Settings', 'flaticon-refresh', '/app/admin/subscription-management'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                new AppMenuItem('Tenant Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings'),
                new AppMenuItem('Tenant Optix Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenants/tenantOptixSettings')
                
                //new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
            ]),

        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {

        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName === '' || subMenuItem.permissionName === null || subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            } else if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {

        if (menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' && this._appSessionService.tenant && !this._appSessionService.tenant.edition) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (this._appSessionService.tenant || !abp.multiTenancy.ignoreFeatureCheckForHostUsers) {
            if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
                hideMenuItem = true;
            }
        }

        // If the asset doesn't have flaring, hide that menu item
        if (menuItem.permissionName === 'Pages.Emissions.Flaring' && this.selectedAsset && !this.selectedAsset.hasFlaring)
            hideMenuItem = true;

        // If the asset doesn't have flaring, hide that menu item
        if (menuItem.permissionName === 'Pages.Emissions' && this.selectedAsset && !this.selectedAsset.hasEmissionAI)
            hideMenuItem = true;

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach(menuItem => {
            allMenuItems = allMenuItems.concat(this.getAllMenuItemsRecursive(menuItem));
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach(subMenu => {
            menuItems = menuItems.concat(this.getAllMenuItemsRecursive(subMenu));
        });

        return menuItems;
    }
}
