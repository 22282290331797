import { Component, Injector, OnInit, ViewChild, TemplateRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { UrlHelper } from '@shared/helpers/UrlHelper';
import { SubscriptionStartType } from '@shared/service-proxies/service-proxies';
import * as moment from 'moment';
import { AppComponentBase } from 'shared/common/app-component-base';
import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from '@app/shared/layout/linked-accounts-modal.component';
import { LoginAttemptsModalComponent } from '@app/shared/layout/login-attempts-modal.component';
import { ChangePasswordModalComponent } from '@app/shared/layout/profile/change-password-modal.component';
import { ChangeProfilePictureModalComponent } from '@app/shared/layout/profile/change-profile-picture-modal.component';
import { MySettingsModalComponent } from '@app/shared/layout/profile/my-settings-modal.component';
import { NotificationSettingsModalComponent } from '@app/shared/layout/notifications/notification-settings-modal.component';
import { UserNotificationHelper } from '@app/shared/layout/notifications/UserNotificationHelper';
import { FormGroup, FormBuilder } from  '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticsService } from '@shared/service-proxies/analytics-service';

// declare let gtag: Function;

@Component({
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less']
})
export class AppComponent extends AppComponentBase implements OnInit, AfterViewInit, OnDestroy {

    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;
    modalRef: BsModalRef;
    // Property: true if a new version exists and a warning has to be shown to the user
    newVersionWarning: boolean;

    latestText = require('../../package.json').latestText;

    @ViewChild('loginAttemptsModal', {static: true}) loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild('linkedAccountsModal') linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild('changePasswordModal', {static: true}) changePasswordModal: ChangePasswordModalComponent;
    @ViewChild('changeProfilePictureModal', {static: true}) changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild('mySettingsModal', {static: true}) mySettingsModal: MySettingsModalComponent;
    @ViewChild('notificationSettingsModal', {static: true}) notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild('chatBarComponent') chatBarComponent;
    @ViewChild('newVersionModalTemplate') templateRef: TemplateRef<any>;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean('App.UserManagement.IsQuickThemeSelectEnabled');
    IsSessionTimeOutEnabled: boolean = this.setting.getBoolean('App.UserManagement.SessionTimeOut.IsEnabled') && this.appSession.userId != null;

    public constructor(
        private modalService: BsModalService,
        injector: Injector,
        public router: Router,
        private _userNotificationHelper: UserNotificationHelper,
        private analyticsService: AnalyticsService
    ) {
        super(injector);
        // Check if an update warning is to be shown.
        this.newVersionWarning = this.checkForNewVersion();
    }
    ngAfterViewInit(): void {
        // Render the Update modal.
        this.showModal();
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal = this.notificationSettingsModal;
        this.theme = abp.setting.get('App.UiManagement.Theme').toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);

        this.registerModalOpenEvents();        
    }

    @HostListener('window:beforeunload')
    @HostListener('window:pagehide')
    ngOnDestroy(): void {
        this.analyticsService.endSession();
    }

    subscriptionStatusBarVisible(): boolean {
        return this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon());
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return moment().utc().add(AppConsts.subscriptionExpireNootifyDayCount, 'days') >= moment(this.appSession.tenant.subscriptionEndDateUtc);
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on('app.show.loginAttemptsModal', () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on('app.show.linkedAccountsModal', () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on('app.show.changePasswordModal', () => {
            this.changePasswordModal.show();
        });

        abp.event.on('app.show.changeProfilePictureModal', () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on('app.show.mySettingsModal', () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger('app.getRecentlyLinkedUsers');
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger('app.onMySettingsModalSaved');
    }

    // Get the versionNumber from the browser's local storage
    getVersion(): string {
        return localStorage.getItem('versionNumber');
    }

    // Set the versionNumber on the browser's local storage to the given parameter.
    setVersion(value: string) {
        localStorage.setItem('versionNumber',value);
    }

    /**
     * Check if the current version and the versionNumber on the browser differ.
     * If the differ return true, otherwise return false.
     */
   checkForNewVersion = () => {
       let lastVersion = this.getVersion();
       
       // Check if lastVersion and version differ.
       // If they differ update the version on the browser and return true.
       if(lastVersion != this.version) {
           this.setVersion(this.version);
           return true;
       }
       else
           return false;
   }
   
   // Render the modal if newVersionWarning property is true.
   private showModal() {
       if (this.newVersionWarning) {
           this.modalRef = this.modalService.show(this.templateRef,
           {
               class: 'modal-dialog-centered'
           });
       }
   }

   // Close the modal.
   closeModal() {
       this.modalRef.hide();
   }
}
