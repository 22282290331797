import { PermissionCheckerService } from 'abp-ng2-module';
import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { AppSessionService } from "@shared/common/session/app-session.service";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { Observable } from "@node_modules/rxjs/internal/Observable";
import { of, Subject } from "rxjs";

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService
    ) {}

    canActivateInternal(
        data: any,
        state: RouterStateSnapshot
    ): Observable<boolean> {

        if (!this._sessionService.user) {
            let sessionObservable = new Subject<any>();

            sessionObservable.next(false);
            sessionObservable.complete();
            this._router.navigate(["/account/login", {requested_path: state?.url}]);

            return sessionObservable;
        }

        if (!data || !data["permission"]) {
            return of(true);
        }

        if (this._permissionChecker.isGranted(data["permission"])) {
            return of(true);
        }

        this._router.navigate([this.selectBestRoute()]);
        return of(false);
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivateInternal(route.data, state);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivate(route, state);
    }

    canLoad(route: any): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateInternal(route.data, { url: location.pathname } as RouterStateSnapshot);
    }

    selectBestRoute(): string {
        if (this._sessionService.tenant.tenantHomePage)
            return this._sessionService.tenant.tenantHomePage;
        
        return "";
    }
}
