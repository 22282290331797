import { NgModule } from "@angular/core";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSliderModule } from '@angular/material/slider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatListModule } from "@angular/material/list";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete'


@NgModule({
    imports:[
        MatAutocompleteModule,
        MatRadioModule,
        MatDialogModule,
        MatNativeDateModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatGridListModule,
        MatListModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatSliderModule,
        MatCheckboxModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatButtonToggleModule,
        MatMomentDateModule,
    ],
    exports:[
        MatAutocompleteModule,
        MatRadioModule,
        MatDialogModule,
        MatNativeDateModule,
        MatMenuModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatGridListModule,
        MatListModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatSliderModule,
        MatCheckboxModule,
        MatIconModule,
        MatPaginatorModule,
        MatTableModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatButtonToggleModule
    ]
})
export class MaterialImportsModule {}