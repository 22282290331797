<div class="kt-footer  kt-footer--extended  kt-grid__item" id="kt_footer">
    <div class="kt-footer__bottom">
        <div [class]="containerClass">
            <div class="kt-footer__wrapper">
                <div>
                    <a routerLink="/" target="_blank" class="kt-brand__logo-wrapper">
                        <img 
                            src="/assets/common/images/app-logo-on-light.png" alt="logo" height="28" />
                    </a>
                    <span class="trademark-text">© {{year}}, Operational Excellence (OPEX) Group Ltd, all rights reserved. <span>Client: v{{ version }}</span></span>
                </div>
                    <div class="footer-custom-text">                         
                            <a target='_blank' href="https://www.opex-group.com/privacy-policy">Privacy Policy </a>
                            <a target='_blank' href="/account/cookie">Cookies Policy </a>
                            <a target='_blank' href="/account/terms">Terms of Use</a>
                    </div>
            </div>
        </div>

        <app-environment-alert-banner></app-environment-alert-banner>
    </div>
</div>

<style>
    .footer-custom-text {
        height: 15px;
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-c4c9c9);
        text-align: right;
        font: normal normal normal 13px/24px Helvetica Neue;
        letter-spacing: 0px;
        color: #C4C9C9;
        opacity: 1;
    }

    .footer-custom-text > a {
        float: right;
        text-decoration: underline;
        margin-right: 0.5rem;
        font-weight: normal;
    }
</style>