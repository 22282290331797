<div class="containerClass" *ngIf="environment==='staging'">
    <alert type="danger">
        <div class="col-12 text-center font-weight-bold">
            !! You are in staging !!
        </div>                
    </alert>
</div>
<div class="containerClass" *ngIf="environment==='development'">
    <alert type="warning">
        <div class="col-12 text-center font-weight-bold">
            !! You are in development !!
        </div>                
    </alert>
</div>
