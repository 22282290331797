
    <div class="kt-portlet kt-portlet--height-fluid expiring-tenants">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        <i class="far fa-clock kt-font-danger"></i>
                        <span
                            class="caption-subject kt-font-danger font-bold uppercase">{{"SubscriptionExpiringTenants" | localize}}</span><br />
                        <small>
                            <span class="caption-helper" *ngIf="expiringTenantsData">
                                {{"ExpiringTenantsHelpText" | localize:expiringTenantsData.subscriptionEndAlertDayCount : expiringTenantsData.maxExpiringTenantsShownCount}}
                            </span>
                        </small>
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                    <div class="scroller chart kt-scroll" [perfectScrollbar]>
                            <div class="primeng-datatable-container expiring-tenants-table"
                                [busyIf]="primengTableHelper.isLoading"  *ngIf="!dataLoading">
                                <p-table #ExpiringTenantsTable [value]="expiringTenantsData.expiringTenants"
                                    rows="{{primengTableHelper.defaultRecordsCountPerPage}}" [paginator]="false" 
                                    [lazy]="false" [responsive]="primengTableHelper.isResponsive">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>{{'TenantName' | localize}}</th>
                                            <th>{{'RemainingDay' | localize}}</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-record="$implicit">
                                        <tr>
                                            <td>
                                                <span class="ui-column-title">{{'TenantName' | localize}}</span>
                                                {{record.tenantName}}
                                            </td>
                                            <td>
                                                <span
                                                    class="ui-column-title">{{'RemainingDay' | localize}}</span>
                                                {{record.remainingDayCount}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                                <div class="primeng-no-data" *ngIf="expiringTenantsData.expiringTenants.length == 0">
                                    {{'NoData' | localize}}
                                </div>
                            </div>
                        </div>
                        <div class="scroller-footer">
                            <div class="btn-arrow-link pull-right">
                                <a href="javascript:;" class="see-all-expiring-tenants"
                                    (click)="gotoAllExpiringTenants()">{{"SeeAllRecords" | localize}}</a>
                                <i class="icon-arrow-right"></i>
                            </div>
                        </div>
            </div>