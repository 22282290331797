import { AbpModule } from 'abp-ng2-module';
import * as ngCommon from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppLocalizationService } from "@app/shared/common/localization/app-localization.service";
import { AppNavigationService } from "@app/shared/layout/nav/app-navigation.service";
import { UtilsModule } from "@shared/utils/utils.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TabsModule } from "ngx-bootstrap/tabs";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from "ngx-bootstrap/datepicker";
import { AlertModule } from "ngx-bootstrap/alert";
import { PaginatorModule } from "primeng/paginator";
import { TableModule } from "primeng/table";
import { AppAuthService } from "./auth/app-auth.service";
import { AppRouteGuard } from "./auth/auth-route-guard";
import { CommonLookupModalComponent } from "./lookup/common-lookup-modal.component";
import { DateRangePickerInitialValueSetterDirective } from "./timing/date-range-picker-initial-value.directive";
import { DatePickerInitialValueSetterDirective } from "./timing/date-picker-initial-value.directive";
import { DateTimeService } from "./timing/date-time.service";
import { TimeZoneComboComponent } from "./timing/timezone-combo.component";
import { DashboardViewConfigurationService } from "./customizable-dashboard/dashboard-view-configuration.service";
import { GridsterModule } from "angular-gridster2";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { NgxBootstrapDatePickerConfigService } from "assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { CountoModule } from "angular2-counto";
import { EnvironmentAlertBannerComponent } from './environment-alert-banner/environment-alert-banner.component';
import { InventoryItemSelectorComponent } from '@app/main/communications/inventory-item-selector/inventory-item-selector.component';
import { MaterialImportsModule } from './material-imports.module';
import { EditorModule } from 'primeng';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FileItemComponent } from './file-item/file-item.component';


@NgModule({
    imports: [
        MaterialImportsModule,
        ngCommon.CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        UtilsModule,
        AbpModule,
        TableModule,
        PaginatorModule,
        GridsterModule,
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        NgxChartsModule,
        BsDatepickerModule.forRoot(),
        PerfectScrollbarModule,
        CountoModule,
        EditorModule,
        AlertModule.forRoot()
    ],
    declarations: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        EnvironmentAlertBannerComponent,
        InventoryItemSelectorComponent,
        FileUploadComponent,
        FileItemComponent
    ],
    exports: [
        TimeZoneComboComponent,
        CommonLookupModalComponent,
        DateRangePickerInitialValueSetterDirective,
        DatePickerInitialValueSetterDirective,
        NgxChartsModule,
        EditorModule,
        InventoryItemSelectorComponent,
        EnvironmentAlertBannerComponent,
        FileUploadComponent,
        FileItemComponent
    ],
    providers: [
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        DashboardViewConfigurationService,
        {
            provide: BsDatepickerConfig,
            useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig,
        },
        {
            provide: BsDaterangepickerConfig,
            useFactory:
                NgxBootstrapDatePickerConfigService.getDaterangepickerConfig,
        },
    ],

    entryComponents: [
    ],
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [AppAuthService, AppRouteGuard],
        };
    }
}
