
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { AbpHttpInterceptor, RefreshTokenService } from 'abp-ng2-module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
    providers: [   
        ApiServiceProxies.InsightServiceProxy,        
        ApiServiceProxies.AbpCommsXInvestigationServiceProxy,        
        ApiServiceProxies.AbpCommsXObservationServiceProxy,        
        ApiServiceProxies.AbpCommsContributionRecordServiceProxy,        
        ApiServiceProxies.AbpCommsConversationRecordServiceProxy,        
        ApiServiceProxies.AbpCommsSystemTeamsServiceProxy,        
        ApiServiceProxies.AbpCommsInventoryServiceProxy,        
        ApiServiceProxies.AssetsServiceServiceProxy,
        ApiServiceProxies.EmissionsServiceProxy,           
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.ChatServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.FriendshipServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.PaymentServiceProxy,
        ApiServiceProxies.InvoiceServiceProxy,
        ApiServiceProxies.SubscriptionServiceProxy,
        ApiServiceProxies.UiCustomizationSettingsServiceProxy,
        ApiServiceProxies.PayPalPaymentServiceProxy,
        ApiServiceProxies.StripePaymentServiceProxy,
        ApiServiceProxies.DashboardCustomizationServiceProxy,
        ApiServiceProxies.OptixSupportsServiceProxy,
        ApiServiceProxies.AbpXInvestigationDocumentServiceServiceProxy,
        ApiServiceProxies.AttachmentsServiceServiceProxy,
        ApiServiceProxies.AbpCommsXInsightDocumentsServiceServiceProxy,
        ApiServiceProxies.AbpCommsInventoryDocumentsServiceProxy,
        ApiServiceProxies.FlaringServiceServiceProxy,
        ApiServiceProxies.AppConfigurationServiceServiceProxy,
        ApiServiceProxies.ReportServiceServiceProxy,
        ApiServiceProxies.AssetUiCardsServiceServiceProxy,
        ApiServiceProxies.UiCardServiceServiceProxy,
        ApiServiceProxies.ApiServiceProxy,
        ApiServiceProxies.CategorySetServiceServiceProxy,
        ApiServiceProxies.AssetSettingsServiceServiceProxy,
        ApiServiceProxies.TenantOptixSettingsServiceServiceProxy,
        CookieService,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
