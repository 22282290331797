<div [ngClass]="{'subscription-bar-visible': subscriptionStatusBarVisible()}">
    <theme2-layout *ngIf="theme=='theme2'"></theme2-layout>
</div>

<loginAttemptsModal #loginAttemptsModal></loginAttemptsModal>
<linkedAccountsModal *ngIf="!installationMode" #linkedAccountsModal (modalClose)="getRecentlyLinkedUsers()"></linkedAccountsModal>
<changePasswordModal #changePasswordModal></changePasswordModal>
<changeProfilePictureModal #changeProfilePictureModal></changeProfilePictureModal>
<mySettingsModal #mySettingsModal (modalSave)="onMySettingsModalSaved()"></mySettingsModal>
<notificationSettingsModal #notificationSettingsModal></notificationSettingsModal>

<theme-selection-panel #themeSelectionPanelComponent *ngIf="!installationMode && isQuickThemeSelectEnabled"></theme-selection-panel>
<session-timeout #sessionTimeout *ngIf="IsSessionTimeOutEnabled"></session-timeout>

<ng-template #newVersionModalTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Update</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <h5 class="text-center">{{latestText}}</h5>
            </div>
            <div class="col-sm-12 mt-4 text-center">
                <button (click)="closeModal()" class="btn-primary">
                    Close
                </button>
            </div>
        </div>
    </div>
</ng-template>