import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-environment-alert-banner',
  templateUrl: './environment-alert-banner.component.html',
  styleUrls: ['./environment-alert-banner.component.css']
})
export class EnvironmentAlertBannerComponent implements OnInit {

  public environment: string;
  constructor() { }

  ngOnInit() {
    // Check and set the current environment by examining at which appSettings file the environment file is pointing.
    if (environment.appConfig === "appconfig.staging.json")
      this.environment = 'staging';
    else if (environment.appConfig === 'appconfig.json')
      this.environment = 'development';
    else
      this.environment = 'production';

  }

}
